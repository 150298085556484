import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";
import { Modal } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from 'date-fns';

const UserDocuments = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const isMountedRef = useRef(false);

  const gContext = useContext(GlobalContext);
  const userId = gContext?.user?.id || '';
  const [userDocuments, setUserDocuments] = useState([]);
  const [fileType, setFileType] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errors, setErrors] = useState({});


  const handleClose = () => {
    setErrors({});
  };

  const handleWordChange = (word) => {
    return word
      .replace(/_/g, ' ')
      .toUpperCase();
  };

  const documentIconMap = {
    resume: 'fas fa-file-alt',
    aadhaar: 'fas fa-id-card',
    other_address_proof: 'fas fa-file',
  };

  const fetchUserDocuments = async () => {

    if (!userId) {
      gContext.toggleSignInModal();
      return
    }

    try {
      const response = await axios.get(`${network.serverip}/api/user-documents-list-web/${userId}`);

      if (response.data.success && isMountedRef.current) {

        const userDocumentsData = response.data.data || [];

        setUserDocuments(userDocumentsData);
        console.log('This is the fetched user documents:', userDocumentsData);

      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setUserDocuments([]);
        }
        console.error('Failed to fetch user documents');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setUserDocuments([]);
      }
      console.error('Error fetching user documents:', error);
    }
  };

  const handleFileDownload = async (fileName, name) => {
    try {
      // Define the document URL
      const documentUrl = `${network.serverip}/storage/${fileName}`;
      console.log('Downloading from URL:', documentUrl);

      // Fetch the document from the server using Axios
      const response = await axios.get(documentUrl, {
        responseType: 'blob' // Important: This ensures that the response is treated as a Blob
      });

      // Check if the response is successful
      if (response.status !== 200) {
        throw new Error('Failed to download document');
      }

      // Convert the response data into a Blob URL
      const fileUrl = window.URL.createObjectURL(response.data);

      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = name; // Set the file name

      // Programmatically trigger a click event to download the file
      document.body.appendChild(a);
      a.click();

      // Cleanup: Remove the anchor and revoke the Blob URL
      a.remove();
      window.URL.revokeObjectURL(fileUrl);

      console.log(`Download complete: ${name}`);

      // Optionally, show a notification after the download completes
      alert(`The file "${name}" has been downloaded successfully.`);

    } catch (error) {
      console.error('Error downloading the document:', error);

      // Optional: Show a notification in case of failure
      alert(`Failed to download the file "${name}". Please try again.`);
    }
  };

  const handleSuccessMessage = (type) => {
    setSuccessMessage(type);

    setTimeout(() => {
      setSuccessMessage('');
    }, 5000);
  };

  const handleUploadButton = (type) => {
    setFileType(type);
    document.getElementById("documentUpload").click();
  };

  const handleDocumentPick = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const type = fileType;
      const document_name = file.name;
      await handleDocumentUpload(file, type, document_name);
    }
  };

  const handleDocumentUpload = async (file, type, document_name) => {

    console.log('Handle document upload pressed:', file, type, document_name);

    if (!userId) {
      return;
    }

    setErrors('');

    try {

      const formData = new FormData();
      formData.append('user_id', userId);
      formData.append('file', file);
      formData.append('type', type);
      formData.append('document_name', document_name);

      const response = await axios.post(`${network.serverip}/api/user-document-upload-web`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

      console.log('This is the response:', response);

      if (response.data.success) {
        await fetchUserDocuments();
        handleSuccessMessage(type);
      }

    } catch (error) {
      console.log('This is the upload document catch response:', error.response);

      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
          return
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            uploadError: error.response.data.message,
          }));
        }

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const handleDocumentDelete = async (id, file_name) => {

    console.log('Handle document delete pressed:', id, file_name);

    if (!userId) {
      return;
    }

    setErrors('');

    try {

      const response = await axios.delete(`${network.serverip}/api/user-document-delete-web`, {
        params: {
          user_id: userId,
          id,
          file_name,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('This is the response:', response);

      if (response.data.success) {
        await fetchUserDocuments();
      }

    } catch (error) {
      console.log('This is the delete document catch response:', error.response);

      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
          return
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            uploadError: error.response.data.message,
          }));
        }

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));
      }
    }
  };


  useEffect(() => {
    if (!userId) {
      console.log('Waiting for the user id');
      return;
    }

    isMountedRef.current = true;

    fetchUserDocuments();

    return () => {
      isMountedRef.current = false;
    };
  }, [userId]);

  const styles = UserDocumentsStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="bg-default-2 pt-32 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            {/* <!-- back Button --> */}
            <div className="row justify-content-center">
              <div className="col-10 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/');
                    }
                  }} className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">

              <div className="col-10">
                <div className="bg-white rounded-4 shadow-9">

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <button
                      onClick={() => { }}
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Upload Documents
                      </h4>
                    </button>

                    <input
                      type="file"
                      id="documentUpload"
                      style={{ display: "none" }}
                      onChange={handleDocumentPick}
                    />

                    <div>
                      {(() => {
                        const doc = userDocuments.find((doc) => doc.type === 'resume') || {};

                        return (
                          <div className="w-100 border-top">
                            <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap">
                              <div className="d-flex align-items-start mt-0 mb-5">
                                <i
                                  className={'fas fa-file-alt font-size-4 text-success'}
                                ></i>
                                <p className="mb-0 font-size-4 text-black-2 font-weight-semibold ml-5">
                                  Resume
                                </p>
                                {successMessage === 'resume' &&
                                  <i className="fas fa-check font-size-4 text-success ml-5"></i>
                                }
                              </div>
                              <div className="w-100 mt-5">
                                <div className="d-flex flex-column
                                flex-lg-row align-items-center justify-content-center
                                justify-content-md-end
                                ">
                                  {doc.file_name &&
                                    <button
                                      style={styles.downloadButton}
                                      className="d-flex mb-5"
                                      onClick={() => handleFileDownload(`documents/${doc.file_name}`, doc.document_name)}
                                    >
                                      <p
                                        className="font-size-4 mb-0 text-dodger font-weight-semibold text-upper text-truncate"
                                        style={{
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          maxWidth: '9.5rem',
                                        }}
                                      >
                                        {doc?.document_name || ''}
                                      </p>
                                      <i className="fas fa-download font-size-4 text-dodger ml-2"></i>
                                    </button>
                                  }
                                  <button
                                    onClick={() => handleUploadButton('resume')}
                                    className="btn btn-outline-success rounded-3 ml-5 mb-5"
                                    style={{ minWidth: '12rem' }}>
                                    <i className="fas fa-upload font-size-4 mr-2"></i>
                                    {doc.file_name ? ' Choose Another File' : 'Choose Resume'}
                                  </button>
                                  {doc.file_name &&
                                    <button
                                      onClick={() => handleDocumentDelete(doc?.id, doc?.file_name)}
                                      className="btn btn-outline-danger rounded-3 ml-5 mb-5"
                                      style={{ minWidth: '12rem' }}>
                                      <i className="fas fa-trash font-size-4 mr-2"></i>
                                      Delete
                                    </button>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })()}
                    </div>

                    <div>
                      {(() => {
                        const doc = userDocuments.find((doc) => doc.type === 'aadhaar') || {};

                        return (
                          <div className="w-100 border-top">
                            <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap">
                              <div className="d-flex align-items-start mt-0 mb-5">
                                <i
                                  className={'fas fa-file-alt font-size-4 text-success'}
                                ></i>
                                <p className="mb-0 font-size-4 text-black-2 font-weight-semibold ml-5">
                                  Aadhaar Card
                                </p>
                                {successMessage === 'aadhaar' &&
                                  <i className="fas fa-check font-size-4 text-success ml-5"></i>
                                }
                              </div>
                              <div className="w-100 mt-5">
                                <div className="d-flex flex-column
                                flex-lg-row align-items-center justify-content-center
                                justify-content-md-end
                                ">
                                  {doc.file_name &&
                                    <button
                                      style={styles.downloadButton}
                                      className="d-flex mb-5"
                                      onClick={() => handleFileDownload(`documents/${doc.file_name}`, doc.document_name)}
                                    >
                                      <p
                                        className="font-size-4 mb-0 text-dodger font-weight-semibold text-upper text-truncate"
                                        style={{
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          maxWidth: '9.5rem',
                                        }}
                                      >
                                        {doc?.document_name || ''}
                                      </p>
                                      <i className="fas fa-download font-size-4 text-dodger ml-2"></i>
                                    </button>
                                  }
                                  <button
                                    onClick={() => handleUploadButton('aadhaar')}
                                    className="btn btn-outline-success rounded-3 ml-5 mb-5"
                                    style={{ minWidth: '12rem' }}>
                                    <i className="fas fa-upload font-size-4 mr-2"></i>
                                    {doc.file_name ? ' Choose Another File' : 'Choose Aadhaar Card'}
                                  </button>
                                  {doc.file_name &&
                                    <button
                                      onClick={() => handleDocumentDelete(doc?.id, doc?.file_name)}
                                      className="btn btn-outline-danger rounded-3 ml-5 mb-5"
                                      style={{ minWidth: '12rem' }}>
                                      <i className="fas fa-trash font-size-4 mr-2"></i>
                                      Delete
                                    </button>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })()}
                    </div>

                    <div>
                      {(() => {
                        const doc = userDocuments.find((doc) => doc.type === 'other_address_proof') || {};

                        return (
                          <div className="w-100 border-top">
                            <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap">
                              <div className="d-flex align-items-start mt-0 mb-5">
                                <i
                                  className={'fas fa-file-alt font-size-4 text-success'}
                                ></i>
                                <p className="mb-0 font-size-4 text-black-2 font-weight-semibold ml-5">
                                  Other Address Proof
                                </p>
                                {successMessage === 'other_address_proof' &&
                                  <i className="fas fa-check font-size-4 text-success ml-5"></i>
                                }
                              </div>
                              <div className="w-100 mt-5">
                                <div className="d-flex flex-column
                                flex-lg-row align-items-center justify-content-center
                                justify-content-md-end
                                ">
                                  {doc.file_name &&
                                    <button
                                      style={styles.downloadButton}
                                      className="d-flex mb-5"
                                      onClick={() => handleFileDownload(`documents/${doc.file_name}`, doc.document_name)}
                                    >
                                      <p
                                        className="font-size-4 mb-0 text-dodger font-weight-semibold text-upper text-truncate"
                                        style={{
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          maxWidth: '9.5rem',
                                        }}
                                      >
                                        {doc?.document_name || ''}
                                      </p>
                                      <i className="fas fa-download font-size-4 text-dodger ml-2"></i>
                                    </button>
                                  }
                                  <button
                                    onClick={() => handleUploadButton('other_address_proof')}
                                    className="btn btn-outline-success rounded-3 ml-5 mb-5"
                                    style={{ minWidth: '12rem' }}>
                                    <i className="fas fa-upload font-size-4 mr-2"></i>
                                    {doc.file_name ? ' Choose Another File' : 'Choose Address Proof'}
                                  </button>
                                  {doc.file_name &&
                                    <button
                                      onClick={() => handleDocumentDelete(doc?.id, doc?.file_name)}
                                      className="btn btn-outline-danger rounded-3 ml-5 mb-5"
                                      style={{ minWidth: '12rem' }}>
                                      <i className="fas fa-trash font-size-4 mr-2"></i>
                                      Delete
                                    </button>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })()}
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default UserDocuments;

function UserDocumentsStyling() {
  const styles = {
    collapsibleHeadingButton:
    {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    downloadButton: {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },
    addButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    editButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    suggestionContainer: {
      maxHeight: "200px",
      overflowY: "auto",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
    },
    suggestionItemStyle: {
      cursor: "pointer",
      backgroundColor: "white",
      border: "none",
      outline: 'none',
    },
    focussedInput: {
      borderColor: '#3498db',
      color: '#333',
      outline: 'none',
      borderWidth: '2px',
      boxShadow: '0 0 5px rgba(52, 152, 219, 0.5)',
    }
  };

  return styles;
}

